 <template>
    <div>
    <!-- 列表 -->
    <div class="user">
      <div class="block-quote">
        <el-form :inline="true" ref="form">
          <el-form-item label="用户ID" prop="order_no">
            <el-input
              style="width: 170px"
              v-model="uid"
              clearable
              placeholder="请输入用户ID"
            ></el-input>
          </el-form-item>
          <el-form-item style="float: right">
            <el-button type="primary" icon="el-icon-search" @click="searchinfo"
              >搜索</el-button
            >
            <el-button type="primary" icon="el-icon-plus" @click="addInfo(1)"
              >新增</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <page-table
        ref="dataTable"
        :data="shoporder"
        @changeCurrentPage="changeCurrent"
      >
      <el-table-column label="序号" align="center">
        <template slot-scope="scope">
          <span>{{
            (page.page - 1) * page.total + scope.$index + 1
          }}</span>
        </template>
      </el-table-column>
        <el-table-column
          prop="uid"
          label="用户ID"
          align="center"
          :resizable="false"
        ></el-table-column>
         <el-table-column
          prop="name"
          label="用户名"
          align="center"
          :resizable="false"
        ></el-table-column>
        <el-table-column
          prop="money"
          label="预充值（总数）"
          align="center"
          :resizable="false"
        ></el-table-column>
        <el-table-column
          prop="pay_money"
          label="消费总额（赠送）"
          align="center"
          :resizable="false"
        ></el-table-column>
        <el-table-column
          prop="pay_coupon"
          label="消费券"
          align="center"
          :resizable="false"
        ></el-table-column>
        <el-table-column
          prop="exchange"
          label="兑换券"
          align="center"
          :resizable="false"
        ></el-table-column>
        <el-table-column
          prop="cash_score"
          label="消费余额"
          align="center"
          :resizable="false"
        ></el-table-column>
        <el-table-column
          prop="score"
          label="总积分（未生效+生效）"
          align="center"
          :resizable="false"
        ></el-table-column>
        <el-table-column
          prop="price"
          label="购物券（提现+复购+剩余）"
          align="center"
          :resizable="false"
        ></el-table-column>
        <el-table-column
          label="操作"
          fixed="right"
          width="120"
          align="center"
          :resizable="false"
          v-if="checkPermission(['useredit', 'userdelete'])"
        >
          <template slot-scope="scope">
             <el-link
              type="primary"
              style="margin-left: 10px"
              @click="records(scope.row)"
              >操作记录</el-link
            >
            <el-link
              type="danger"
              style="margin-left: 10px"
              @click="change(1,scope.row)"
              >操作</el-link
            >
            <el-link
              type="danger"
              style="margin-left: 10px"
              @click="editInfo(2,scope.row)"
              >编辑</el-link
            >
          </template>
        </el-table-column>
      </page-table>
    </div>
    <add-Dialog ref="AddDialog"></add-Dialog>
    <edit-Dialog ref="EditDialog"></edit-Dialog>
    <el-dialog title="操作记录"
      :visible.sync="dialogVisible"
      width="900px"
      :close-on-click-modal="false"
      @close="close">
      <el-table
        ref="dataTable"
        border
        stripe
        :data="recordsList"
        @changeCurrentPage="changeCurrent"
      >
      <el-table-column label="序号" align="center">
        <template slot-scope="scope">
          <span>{{
            (page.page - 1) * page.total + scope.$index + 1
          }}</span>
        </template>
      </el-table-column>
        <el-table-column
          prop="log"
          label="操作"
          align="center"
          :resizable="false"
        ></el-table-column>
         <el-table-column
          prop="create_time"
          label="时间"
          align="center"
          :resizable="false"
        ></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { checkPermission } from "@/utils/permissions";
import {malluserInfo,malluserInfoLog} from "@/request/api";
import pageTable from "@/components/pageTable.vue";
import AddDialog from "./components/addDialog"
import EditDialog from './components/editDialog'
export default {
  name: "specialorder",
  components: {
    pageTable,
    AddDialog,
    EditDialog,
  },
  data() {
    return {
      uid:"",
      shoporder:[],
      recordsList:[],
      dialogVisible:false,
      page: {
        //分页信息
        page: 1, //当前页
        limit: 10, //每页条数
        total: 0, //总条数
      },
      page1: {
        //分页信息
        page: 1, //当前页
        limit: 10, //每页条数
        total: 0, //总条数
      },
    };
  },
  created() {
    this.shoporderlist();
  },
  mounted() {},
  methods: {
    close() {
      this.dialogVisible = false;
    },
    checkPermission,
    checkPermission,
    // 切换分页
    changeCurrent(page, size) {
      this.page.page = page;
      this.page.limit = size;
      this.shoporderlist();
    },
    async searchinfo() {
      this.page.page = 1;
      this.shoporderlist()
    },
    async shoporderlist() {
      this.token = sessionStorage.getItem("token");
      let res = await malluserInfo({
        token: sessionStorage.getItem("token"),
        page: this.page.page,
        limit: this.page.limit,
        uid:this.uid
      });
      if (res.status == 200) {
        console.log(res,'list');
        this.shoporder = res.data.data;
        this.$refs.dataTable.setPageInfo({
          total: res.data.count,
        });
      }
    },
    change(type,row){
        this.$refs.EditDialog.show(type,JSON.parse(JSON.stringify(row)))
    },
    addInfo(type){
        this.$refs.AddDialog.show(type,{})
    },
    records(row){
        let params={
            token:sessionStorage.getItem("token"),
            uid:row.uid,
            page:this.page1.page,
            limit:this.page1.limit
        }
        malluserInfoLog(params).then((res)=>{
            console.log(res,'77777777')
            if(res.data.code==200){
                this.recordsList=res.data.data

            }
            this.dialogVisible=true
        })
    },
    editInfo(type,row){
        this.$refs.AddDialog.show(type,JSON.parse(JSON.stringify(row)))
    }
  },
};

</script>

<style lang="scss" scoped>
.census {
  padding-left: 80px;
}
</style>
