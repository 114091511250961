<template>
  <div>
    <el-dialog
      :title="type == 1 ? '新增' : '编辑'"
      :visible.sync="dialogVisible"
      width="900px"
      :close-on-click-modal="false"
      @close="close"
    >
      <el-form
        :model="goodsForm"
        ref="goodsForm"
        :rules="rules"
        label-width="auto"
      >
        <el-col :span="12" v-if="type==1">
          <el-form-item label="用户ID" prop="uid" >
            <el-input v-model="goodsForm.uid" placeholder="请输入用户ID" style="width: 280px">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="真实姓名" prop="name">
            <el-input
              v-model="goodsForm.name"
              placeholder="请输入真实姓名"
               style="width: 280px"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="手机号" prop="phone" >
            <el-input
              v-model="goodsForm.phone"
              placeholder="请输入手机号"
              style="width: 280px"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="银行卡" prop="idcard" >
            <el-input
              v-model="goodsForm.idcard"
              placeholder="请输入银行卡"
              style="width: 280px"
              @blur="getBank"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="所属银行" prop="bank" >
            <el-select disabled v-model="goodsForm.bankId" placeholder="请选择所属银行" style="width: 280px">
              <el-option v-for="item in bankArr" :key="item.id" :label="item.name" :value="item.bin"></el-option>
          </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="type==1">
          <el-form-item label="预充值" prop="money" >
            <el-input
              v-model="goodsForm.money"
              placeholder="请输入预充值"
              style="width: 280px"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {malluserInfoAdd,getBank,userInfoUpdate} from "@/request/api"
export default {
  name: 'AddDialog',

  data() {
    return {
      type:"",
      dialogVisible:false,
      goodsForm:{
        uid:"",
        name:"",
        phone:"",
        idcard:"",
        money:0,
        bankId:"",
        id:"",
      },
      
        bankArr:"",
        number:"",
      rules: {
        uid: [
          { required: true, message: "请输入用户ID", trigger: "blur" },
        ],
      },
    };
  },

  mounted() {
    
  },
  methods: {
    show(type,row){
      console.log(row,'4444444')
        this.type=type
        this.dialogVisible=true
        if(type==2){
            this.goodsForm.uid=row.uid
            this.goodsForm.id=row.id
            this.goodsForm.name=row.name
            this.goodsForm.phone=row.phone
            this.goodsForm.idcard=row.idcard
            this.goodsForm.money=row.all_money

        this.getBank()


        }
    },
    submitForm(){
        this.$refs.goodsForm.validate(async (valid) => {
            if(valid){
                if(this.type==1){
                   let params={
                     token:sessionStorage.getItem("token"),
                        uid:this.goodsForm.uid,
                        name:this.goodsForm.name,
                        phone:this.goodsForm.phone,
                        idcard:this.goodsForm.idcard,
                        all_money:this.goodsForm.money
                    }
                    malluserInfoAdd(params).then((res)=>{
                        console.log(res);
                        if(res.data.code==200){
                            this.$message.success("新增成功!");
                            this.$parent.shoporderlist();
                            this.close();
                        }
                        else{
                            this.$message.error(res.data.msg)
                            this.close()
                        }
                    })
                }
                if(this.type==2){
                   let params={
                     token:sessionStorage.getItem("token"),
                        uid:this.goodsForm.uid,
                        name:this.goodsForm.name,
                        phone:this.goodsForm.phone,
                        idcard:this.goodsForm.idcard,
                        all_money:this.goodsForm.money,
                        id:this.goodsForm.id
                    }
                    userInfoUpdate(params).then((res)=>{
                        console.log(res);
                        if(res.data.code==200){
                            this.$message.success("修改成功!");
                            this.$parent.shoporderlist();
                            this.close();
                        }
                        else{
                            this.$message.error(res.data.msg)
                            this.close()
                        }
                    })
                }
                
            }
        })
    },
    close(){
        this.goodsForm.uid=""
        this.goodsForm.name=""
        this.goodsForm.phone=""
        this.goodsForm.idcard=""
        this.goodsForm.money=""
        this.goodsForm.bankId=""
        this.dialogVisible=false
    },
    getBank(number){
      this.number=this.goodsForm.idcard.slice(0,6)
      this.goodsForm.bankId=this.number
      console.log(this.number)
      let params={
        token:sessionStorage.getItem("token"),
        number:this.number
      } 
      getBank(params).then((res)=>{
        console.log(res)
        this.bankArr=res.data.data
      })
      
    }
  },
};
</script>