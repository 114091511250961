<template>
  <div>
    <el-dialog
      :title="type == 1 ? '新增' : '编辑'"
      :visible.sync="dialogVisible"
      width="900px"
      :close-on-click-modal="false"
      @close="close"
    >
      <el-form
        :model="goodsForm"
        ref="goodsForm"
        :rules="rules"
        label-width="auto"
      >
        <el-col :span="12">
          <el-form-item label="预充值" prop="coupon" >
            <el-input v-model="goodsForm.coupon" placeholder="请输入预充值" style="width: 280px">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="预充值转消费券" prop="money">
            <el-input
              v-model="goodsForm.money"
              placeholder="请输入预充值转消费券的值"
               style="width: 280px"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {userInfoEdit} from "@/request/api"
export default {
  money: 'AddDialog',

  data() {
    return {
      type:"",
      uid:"",
      dialogVisible:false,
      goodsForm:{
        coupon:0,
        money:0,
      },
      rules: {
        coupon: [
          { required: true, message: "请输入预充值", trigger: "blur" },
        ],
        money: [
          { required: true, message: "请输入预充值转化的消费券", trigger: "blur" },
        ],
      },
    };
  },

  mounted() {
    
  },
  methods: {
    show(type,row){
        this.type=type
        this.dialogVisible=true
        this.uid=row.uid
        if(type==2){
            this.goodsForm.coupon=row.coupon
            this.goodsForm.money=row.money
        }
    },
    submitForm(){
        this.$refs.goodsForm.validate(async (valid) => {
            if(valid){
                if(this.type==1){
                   let params={
                        token:sessionStorage.getItem("token"),
                        uid:this.uid,
                        coupon:this.goodsForm.coupon,
                        money:this.goodsForm.money,
                    }
                    userInfoEdit(params).then((res)=>{
                        console.log(res);
                        if(res.data.code==200){
                            this.$message.success("操作成功!");
                            this.$parent.shoporderlist();
                            this.close();
                        }
                        else{
                            this.$message.error(res.data.msg)
                            this.close()
                        }
                    })
                }
                
            }
        })
    },
    close(){
        this.goodsForm.coupon=0
        this.goodsForm.money=0
        this.dialogVisible=false
    }
  },
};
</script>